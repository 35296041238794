
import { computed, defineComponent, ref, watch } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import CModal from "@/components/modal.vue";
import CFormInputText from "@/components/form/input-text.vue";

import {
  formatCPF,
  formatCreditCard,
  removeFormatCreditCard,
  addDateMMAAAAMask,
  removeDateMMAAAAMask,
  removeCPFMask,
  formatDateMMAAAA,
} from "@/utils/formatters";
import { CreditCardForm } from "@/store/credit-card/types";
import { isEditFormByRouteName } from "@/utils/checkers";

const VCreditCardForm = defineComponent({
  name: "VCreditCardForm",
  components: { CModal, CFormInputText },
  setup() {
    useHead({ title: "Dados do cartão | obmed" });

    const store = useStore();
    const router = useRouter();
    const isEditForm = isEditFormByRouteName(router.currentRoute.value.name);
    if (!isEditForm) store.commit("setCreditCardForm", null);

    const creditCard = computed(() => store.state.creditCard.form);

    function generateForm(): CreditCardForm {
      return {
        nr_cpf: creditCard.value?.nr_cpf || "",
        card_number: creditCard.value?.card_number || "",
        card_holder_name: creditCard.value?.card_holder_name || "",
        card_cvv: creditCard.value?.card_cvv || "",
        card_expiration_date: formatDateMMAAAA(creditCard.value?.card_expiration_date) || "",
      };
    }

    const form = ref(generateForm());

    function handleClose() {
      router.back();
    }

    function handleConfirm() {
      const cardDate = form.value.card_expiration_date;

      if (cardDate.length < 6)
        return store.commit("addToast", {
          summary: "Formato de data invalido! mm/aaaa",
          severity: "warn",
        });

      store.commit("setCreditCardForm", {
        ...form.value,
        card_expiration_date: `${cardDate.slice(0, 2)}${cardDate.slice(4)}`,
      });

      handleClose();
    }

    watch(creditCard, generateForm);

    return {
      ...{ form },
      ...{ handleConfirm, handleClose },
      ...{
        addDateMMAAAAMask,
        removeDateMMAAAAMask,
        formatCreditCard,
        removeFormatCreditCard,
        formatCPF,
        removeCPFMask,
      },
    };
  },
});

export default VCreditCardForm;
